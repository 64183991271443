import { Box, createTheme, CssBaseline, ThemeProvider, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import NavBar from '../components/NavBar';
import { AddPoster } from '../components/poster/AddPoster';
import { PosterList } from '../components/poster/PosterList';

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

export const MainPage = ({ user }) => {
    const [mode, setMode] = useState<'light' | 'dark'>('light');
    const [loaded, setLoaded] = useState(false)

    const colorMode = useMemo(
        () => ({
            toggleColorMode: async () => {

                setMode((prevMode) => (
                    prevMode === 'light' ? 'dark' : 'light'
                ));                
            },
        }),
        [],
    );

    useEffect(() => {
        fetch("api/user/preference/colorMode")
            .then((response) => {
                if (response.ok){
                    return response.json()
                }    
                throw new Error("No preference found");
                            
            })
            .then((data) => {
                setMode(data.PrefValue ? data.PrefValue : "light")                        
            })
            .catch((error)=>{
                console.log(error);
            })
            .finally(()=>{
                setLoaded(true)
            })            ;
    }, []);

    useEffect(() => {
        const updatePref = async () => {                       
            const pref = {
                PrefKey: "colorMode",
                PrefValue: mode
            };
            await fetch("api/user/preference", {
                method: "POST",
                body: JSON.stringify(pref)
            });
        };
        if (loaded){
            updatePref().catch(console.error);
        }        

    }, [mode]);



    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode],
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <NavBar user={user} />
                    <Box m={5}>
                        <PosterList />
                        <AddPoster />
                    </Box>
                </ThemeProvider>
            </ColorModeContext.Provider >
        </Box>

    );
};