import { BorderColor } from '@mui/icons-material';
import { Card, CardMedia } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors';
import React from 'react'
import { IPoster } from '../../interfaces/IPoster'

interface IProps {
    poster: IPoster
}

export const Poster = ({ poster }: IProps) => {
    return (
            <Card sx={{maxWidth: 200, border: 1,  borderColor: 'grey.800'}}>
                <CardMedia
                    image={poster.Uri}
                    title={poster.Title}
                    sx={{height:296}}
                />                          
            </Card>
        
    )
}