import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface PFile extends File {
    preview: string;
}

export const PosterDropZone = () => {
    const [files, setFiles] = useState<PFile[] | never>([]);
    const [file, setFile] = useState<PFile | null>(null);

    const onDrop = useCallback(
        (acceptedFiles) => {
            var file = acceptedFiles[0];
            Object.assign(file, { preview: URL.createObjectURL(file) });
            setFile(file);

        },
        [],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: { 'image/*': [] }
    });

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => { if (file) { URL.revokeObjectURL(file.preview); } };
    }, []);

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })} className="dropZone">
                <input {...getInputProps()} />
                {file ? (
                    <>
                        <img
                            src={file.preview}
                            className="dropZoneThumb"
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(file.preview); }}
                        />
                        <div className="overlay">
                            <div className="text">
                            Drag 'n' drop Poster Here
                            </div>                            
                        </div>
                    </>
                ) : (
                    <p>Drag 'n' drop Poster Here</p>
                )}
            </div>
        </section>
    );
};