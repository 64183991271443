import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Poster } from './Poster'

export const PosterList = () => {
    const [posters, setPosters] = useState<any>([])

    useEffect(() => {
        var data = fetch("/api/poster")
        .then((response) => {
            return response.json();
        })
        .then((data) => setPosters([...data]))

        //get the posters here
        //setPosters([...myPosters])
    }, [])

    const myPosters = [
        {
            "id": 742,
            "name": "Gross",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 951,
            "name": "Waters",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 112,
            "name": "Mccormick",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 737,
            "name": "Molina",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 871,
            "name": "Camacho",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 788,
            "name": "Wilkins",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 111,
            "name": "David",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 837,
            "name": "Farley",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 409,
            "name": "Raymond",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 897,
            "name": "Chambers",
            "posterUrl": "http://placehold.it/32x32"
        },
        {
            "id": 277,
            "name": "Weaver",
            "posterUrl": "http://placehold.it/32x32"
        }
    ]

    //create the list of cards here
    const posterList = posters.map((p) =>
    {
        return (
            <Grid item xs={12} md={4} lg={1} key={p.Id}>
                <Poster poster={p} />
            </Grid>
        )
    });

    return (
        <Grid container spacing={2}>
            {posterList}
        </Grid>
    )
}