import { Autocomplete, Box, Button, debounce, Grid, TextField } from '@mui/material';
import { stringify } from 'querystring';
import React, { useState, MouseEvent, useMemo, useEffect } from 'react';
import { searchForMovie } from '../../services/TMDBService';
import { PosterDropZone } from './PosterDropZone';


interface IPoster {
    label: string;
    year: number;
}

export interface IMovie {
    Title: string;
    Id: number;
    release_date: string;
}

export const AddPoster = () => {
    const [title, setTitle] = useState<IMovie | null>();
    const [inputValue, setInputValue] = React.useState('');
    const [uri, setUri] = useState("");
    const [movies, setMovies] = useState<readonly IMovie[]>([]);


    useEffect(() => {
        if (inputValue === '') {
            setMovies(title ? [title] : []);
            return undefined;
        }

        fetchMovie(inputValue);

    }, [title, inputValue]);


    const addPoster = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const poster = {
            "title": title,
            "uri": uri,
            "genres": []
        };
        var data = fetch("/api/poster", {
            method: "POST",
            body: JSON.stringify(poster)
        })
            .then((response) => {
                response.json();
            })
            .then((data) => console.log(data));
    };

    const movieSearch = (search: string) => {
        searchForMovie(search).then(results => {
            console.log("results");
            console.log(results);
            let newMovies: readonly IMovie[] = [];

            if (title) {
                newMovies = [title];
            }

            if (results) {
                newMovies = [...newMovies, ...results];
            }
            console.log("new movies");
            console.log(newMovies);
            setMovies(newMovies);
        });
    };


    const fetchMovie = useMemo(() => debounce((search: string) => { movieSearch(search); }, 400,), []);

    return (
        <Box
            component="form"
            noValidate
            autoComplete='off'
            sx={{m:1, width:800}}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        id="google-map-demo"
                        sx={{ width: 300 }}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.Title
                        }
                        filterOptions={(x) => x}
                        options={movies}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={title}
                        noOptionsText="No locations"
                        onChange={(event: any, newValue: IMovie | null) => {
                            setTitle(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Add a Movie" fullWidth />
                        )}
                        renderOption={(props, options) => {
                            return (
                                <li {...props} key={options.Id}>
                                    <Grid container alignItems="center">
                                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                            <Box
                                                key={options.Id}
                                                component="span"
                                            >
                                                {options.Title}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PosterDropZone />
                </Grid>
                <Grid item>
                    <Button variant="contained" type='submit' onClick={addPoster}>Add Poster</Button>
                </Grid>
            </Grid>
            
        </Box>
    );


};