import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from '@mui/material';
import PrivateHome from './components/PrivateHome';
import PublicHome from './components/PublicHome';
import NavBar from './components/NavBar';
import { LoginPage } from './components/LoginPage';
import { MainPage } from './pages/MainPage';

function App() {
  const [posters, setPosters] = useState<any>([])
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {

        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        
        if(clientPrincipal){
          setUser(clientPrincipal);
          userHasAuthenticated(true);
          console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
        } 
        
    } catch (error:any) {
        console.error('No profile could be found ' + error?.message?.toString());
    }
};  

  const getDataFromApi = async(e: any) => {
    e.preventDefault();
    const data = await fetch('/api/poster');
    const json = await data.json();
    setPosters([...json])    
  }
  return (
    <div className="App">
      {isAuthenticated ? <MainPage user={user}/> : <LoginPage user={user} />}
    </div>
  );
}

export default App;

/*
<NavBar user={user}/>
      <main className="column">
        { isAuthenticated ? <PrivateHome user={user}/> : <PublicHome /> }
      </main>*/
